@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    --base-bg: 26 32 44;
    --base-card:  42 55 73;
    --base-border-dark:  42 55 73;
    --base-border-light: 144 205 244;
    --base-main: 26 54 93;
    --base-text: 190 227 248;
    --base-text-hover: 161 213 246;
    --base-dark-text: 26 32 44;
    --base-button: 144 205 244;
    --base-button-hover: 161 213 246;

    --color-dark-text: rgb(var(--base-dark-text));
    --color-bg: rgb(var(--base-bg));
    --color-card: rgb(var(--base-card));
    --color-border-dark: rgb(var(--base-border-dark));
    --color-border-light: rgb(var(--base-border-light));
    --color-main: rgb(var(--base-main));
    --color-text: rgb(var(--base-text));
    --color-text-hover: rgb(var(--base-text-hover));
    --color-button: rgb(var(--base-button));
    --color-button-hover: rgb(var(--base-button-hover));
  }
}

.App {
  text-align: center;
}

body {
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  color: var(--color-text);
  background-color: var(--color-bg);
}

a:hover {
  color: var(--color-text-hover);
}

button {
  color: var(--color-dark-text);
  background-color: var(--color-button);
  font-weight: 700;
}

button:hover {
  background-color: var(--color-button-hover);
}

button:disabled {
  background-color: var(--color-button);
  opacity: 0.5;
  font-weight: 500;
}

button:disabled:hover {
  cursor: not-allowed;
}

input {
  color: var(--color-dark-text);
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
